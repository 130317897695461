'use strict';

/*******************************************************************************************/
class UxrEeCheckboxGroup extends React.Component {
	constructor(props) {
		super(props);
		this.defaultValue = {'options': []};
		this.state = {
			'value': this.props.value || this.props.defaultValue || this.defaultValue,
		};
		
		this.onChange = this.onChange.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		let children = this.props.options.map((each) => (
			<div key={each.value}>
				<UxrEe_.CheckboxGroup.Checkbox value={each.value} />
				<label>{each.label}</label>
			</div>
		));
		
		return (
			<div {...this.props.htmlProps}>
				<UxrEe_.CheckboxGroup.CheckboxGroup value={this.state.value.options} onChange={this.onChange}>
					{children}
				</UxrEe_.CheckboxGroup.CheckboxGroup>
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onChange(value) {
		value = {options:value};
 		this.setState({
			'value': value
		});
		this.props.onChange(this, {value});
	}
	
} //class
